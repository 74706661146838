:root {
  --WHITE_COLOR: #ffffff;
  --GREEN_COLOR: #99cc00;
  --bs-body-font-family: "Roboto";
  --BLACK_COLOR: #000000;
  --DARK_GREEN_COLOR: #007f00;
  --YELLOW_COLOR: #ffe600;
  --LIGHT_GRAY_COLOR: #ebebeb;
  --OLIVE_GREEN_COLOR: #004f00;
  --DARK_GRAY_COLOR: #666666;
  --SEA_MIST_COLOR: #bedbbf;
  --MEDIUM_LIGHT_GRAY: #999999;
  --ORANGE_COLOR: #ff6600;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Roboto Bold";
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Light";
  src: url("./fonts/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Regular";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Monon";
  src: url("./fonts/RobotoMono-Regular.ttf") format("truetype");
}
@media (max-width: 375px) {
  .container {
    font-size: 10px !important;
  }
}
#svt_font {
  font-family: "Roboto Regular", sans-serif;;
  color: var(--BLACK_COLOR);
  overflow-x: hidden;
  letter-spacing: 0.5px;
}
.container {
  max-width: 1337px !important;
}
.svt-bold-text {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
/* Settings Components CSS */
.order-setting {
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  /* overflow-y: scroll; */
  line-height: 1.5rem;
  color: #000000;
  padding-top: 20px;
}
.order-setting-container {
  display: grid;
  grid-template-rows: 0.7fr 2.62fr 3.59fr 1.09fr;
}
.settings-about {
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 2fr;
}
.go-back-btn {
  background: #f7f7f7;
  /* Primary/Green */

  border: 1px solid #007f00 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.logout-btn {
  background: #f7f7f7;
  /* Primary/Green */

  border: 1px solid #007f00 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  margin-top: 1.25rem !important;
}

.form-check-input:checked {
  background: var(--OLIVE_GREEN_COLOR);
  transform: matrix(0, -1, -1, 0, 0, 0);
  border: 2px solid #ffffff;
  outline: 2px solid var(--OLIVE_GREEN_COLOR);
}
.form-check-input:checked[type="radio"] {
  background-image: none;
}
.setting-accordion .card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: inherit;
  border-bottom: none;
}

.setting-footer {
  background-color: #f7f7f7;
}
.setting-accordion-card {
  margin-left: 4.125rem;
}
.setting-about-row-gap {
  row-gap: 0.625rem;
  margin-top: 15px;
}
.setting-about-logo {
  padding-right: 2.25rem;
}
.setting-accordion-more-top-img {
  margin-left: -0.875rem;
}
.setting-accordion-more-txt {
  padding-left: 0.875rem;
}
/* for scrollbar */
.scrollbar {
  min-height: 75vh;
  max-height: 75vh;
  overflow-y: scroll;
}
.scrollbar::-webkit-scrollbar {
  width: 0.313rem;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
  border-radius: 5px;
}
/* Handling Radio button label in nobile device */
@media (max-width: 400px) {
  .form-check-label {
    font-size: 10px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
}

.Accordion_Body::-webkit-scrollbar,
.menu-table::-webkit-scrollbar,
.order_list_data::-webkit-scrollbar {
  border-radius: 5px;
  width: 4px;
}

.histoy-table-height::-webkit-scrollbar-thumb,
.no-summary-height::-webkit-scrollbar-thumb,
.menu-table::-webkit-scrollbar-thumb,
.fixed_block::-webkit-scrollbar-thumb,
.Accordion_Body::-webkit-scrollbar-thumb {
  background: var(--SEA_MIST_COLOR);
  border-radius: 10px;
}
.order-container {
  height: 638px;
  margin-left: 0px;
  margin-top: 130px;
  margin-right: 25px;
}
.incoming-tab-container {
  height: 638px;
  margin-left: 25px;
  margin-top: 130px;
}
.container-shadow {
  background: var(--WHITE_COLOR);
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0px 0px;
}
.header_text {
  font-size: 18px;
  color: var(--OLIVE_GREEN_COLOR);
  text-decoration: none;
  padding-top: 4px;
  padding-bottom: 2px;
  margin-left: 44px;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.header_text:hover {
  color: var(--OLIVE_GREEN_COLOR);
}
.notification_style {
  padding: 6px 10px !important;
  border-radius: 54px;
  font-size: 12px;
  color: black;
  line-height: 16px;
}
.org_background_color {
  background-color: var(--ORANGE_COLOR);
}
.green_background-color {
  background-color: var(--GREEN_COLOR);
}
.darkgreen-background-color {
  background-color: var(--DARK_GREEN_COLOR) !important;
}
.notification_position {
  position: absolute;
  top: 0px;
  right: 0px;
}
.active_class {
  border-bottom: 3px solid var(--YELLOW_COLOR);
  width: fit-content;
}
.non_active_class {
  border-bottom: 3px solid var(--WHITE_COLOR);
}
.parent_block {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.fixed_block {
  max-height: 568px;
  overflow-y: auto;
}
.navigation-bar {
  height: 110px;
}
.order_header {
  position: static;
  padding-top: 2px;
  margin: 0px 22px;
  width: 51px;
}

.order-status {
  width: 150px !important;
  height: 24px;
  margin-left: 100px;
  top: 20px;
}
.order-eta {
  width: 30px !important;
  height: 24px;
  margin-left: 115px;
  top: 20px;
}
.incoming-order-header {
  position: static;
  /* margin-top: 2px; */
  margin: 0px 22px;
  padding: 0px;
  cursor: pointer;
}
.content-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.incoming-order-count {
  padding: 1px 8px;
  width: 27px;
  height: 28px;
  margin-left: 12px;
  background: var(--GREEN_COLOR);
  border-radius: 54px;
  line-height: 1.5;
}
.just-cancelled-header {
  padding: 0px;
  margin: 0px 18px;
  cursor: pointer;
}
.selected-tab {
  border-radius: 4px;
  border-bottom: 3px solid var(--YELLOW_COLOR);
  padding-bottom: 7px;
}
.order-help {
  width: 157px;
  height: 44px;
  margin: auto;
  float: right;
}
.Accordion_Body::-webkit-scrollbar-thumb,
.order_list_data::-webkit-scrollbar-thumb {
  background: var(--SEA_MIST_COLOR);
  border-radius: 10px;
}
/*
.order-container {
  height: 638px;
  margin-left: 0px;
  margin-top: 130px;
  margin-right: 25px;
}
.incoming-tab-container {
  height: 638px;
  margin-left: 25px;
  margin-top: 130px;
}

.container-shadow {
  background: var(--WHITE_COLOR);
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0px 0px;
}

.header_text {
  font-size: 18px;
  color: var(--OLIVE_GREEN_COLOR);
  text-decoration: none;
  padding-top: 4px;
  padding-bottom: 2px;
  margin-left: 44px;
  line-height: 21px;
  letter-spacing: 0.15px;
}
*/

.notification_style {
  padding: 6px 10px;
  border-radius: 54px;
  font-size: 12px;
  line-height: 16px;
  min-width: 27px;
  max-height: 28px;
}
/*
.org_background_color {
  background-color: var(--ORANGE_COLOR);
}

.green_background-color {
  background-color: var(--GREEN_COLOR);
}
.notification_position {
  position: absolute;
  top: 0px;
  right: 0px;
}
.active_class {
  border-bottom: 3px solid var(--YELLOW_COLOR);
  width: fit-content;
}
.non_active_class {
  border-bottom: 3px solid var(--WHITE_COLOR);
}
*/

.fixed_block {
  max-height: 75vh;
  overflow-y: scroll;
  margin-right: -16px;
  padding-right: 16px;
}
.fixed_block::-webkit-scrollbar {
  width: 0px;
}

/*.navigation-bar {
  height: 110px;
}*/
.order_header {
  position: static;
  /* margin-top: 2px; */
  margin: 0px 2px 0px 20px;
  width: 51px;
}
.order_table_container {
  margin: 20px;
  margin-top: 20px;
  border: 2px solid var(--LIGHT_GRAY_COLOR);
  box-sizing: border-box;
  border-radius: 4px;
  max-height: 75vh;
}

.order_list_data {
  max-height: 65vh;
  overflow-y: auto;
}
/*
.order-status {
  width: 150px !important;
  height: 24px;
  margin-left: 100px;
  top: 20px;
}
.order-eta {
  width: 30px !important;
  height: 24px;
  margin-left: 115px;
  top: 20px;
}
*/
.content-display-live-order {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding-left: 44px;
  /* margin-right: -0.5rem; */
}
.incoming-order-count {
  padding: 1px 8px;
  width: 27px;
  height: 28px;
  margin-left: 12px;
  background: var(--GREEN_COLOR);
  border-radius: 54px;
  line-height: 1.5;
}
/*
.just-cancelled-header {
  padding: 0px;
  margin: 0px 18px;
  cursor: pointer;
}
.selected-tab {
  border-radius: 4px;
  border-bottom: 3px solid var(--YELLOW_COLOR);
  padding-bottom: 7px;
}
.order-help {
  width: 157px;
  height: 44px;
  margin: auto;
  float: right;
}
*/
.order-help.btn-primary {
  background: #f7f7f7;
  border: 1px solid var(--DARK_GREEN_COLOR);
  color: var(--DARK_GREEN_COLOR);
  font-size: 18px;
}
.order-image {
  width: 54.35px;
  height: 54.35px;
  margin: 1.47%;
}
.order-title-margin {
  margin: 0px 20px;
  padding: 18px 0px 20px;
}
.order-title-margin-driver {
  margin: 0px 20px;
}
.order-title {
  font-size: 34px;
  line-height: 36px;
  margin: 10px 14px;
  word-break: break-all;
}
.order-header-bar {
  padding: 14px 20px;
  height: 52px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 9px;
  background: var(--OLIVE_GREEN_COLOR);
  border-radius: 8px;
}
.order-header-text {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.18px;
}
.order-instructions {
  margin-left: 20px;
  margin-top: 20px;
}
.order-instruction-text {
  margin-right: 20px;
}
.order-customer-details {
  background: #f7f7f7;
  padding: 14px 20px 0px;
  height: 46px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.order-contact {
  margin-left: auto;
}
.menu-table {
  max-height: 50vh;
  margin: 0px 20px 0px;
  border-top: 2px solid var(--LIGHT_GRAY_COLOR);
  border-left: 2px solid var(--LIGHT_GRAY_COLOR);
  border-right: 2px solid var(--LIGHT_GRAY_COLOR);
  border-bottom: 2px solid var(--LIGHT_GRAY_COLOR);
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 20px;
  padding-top: 12px;
  padding-right: 20px;
  overflow-y: auto;
}
.menu-header {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #000000;
}
.print-menu-header {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--DARK_GRAY_COLOR);
}
.menu-items {
  margin: 0px 0px;
}
.menu-category {
  text-transform: uppercase;
  color: var(--DARK_GRAY_COLOR);
}
.order-footer {
  position: relative;
  bottom: 0px;
  height: 85px;
  background: #f7f7f7;
}
.footer-btn {
  margin-top: 22px;
  margin-left: auto;
}
.btn-ready-pickup {
  padding: 10px 12px;
  width: 215px;
  margin-right: 24px;
  background: var(--DARK_GREEN_COLOR);
  color: var(--WHITE_COLOR);
  border-radius: 4px;
}
.btn-back {
  padding: 14px 18px;
  width: 80px;
  background: #f7f7f7;
  border: 1px solid var(--OLIVE_GREEN_COLOR);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 20px;
  color: var(--DARK_GREEN_COLOR);
}
.order-detail-btn {
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  height: 44px;
}
.printer-icon {
  padding: 0px;
  width: 44px;
  height: 44px;
  margin-top: 22px;
  margin-left: 24px;
  background: #f7f7f7;
  border: 1px solid var(--DARK_GREEN_COLOR);
  box-sizing: border-box;
  border-radius: 4px;
}
.menu-sub-categoy {
  padding-left: 14% !important;
}

.card_block {
  background: var(--WHITE_COLOR);
  box-shadow: 0px 1px 12px rgb(0 0 0 / 20%);
  border-radius: 4px 4px 0px 0px;
  max-height: 100vh;
  min-height: 85vh;
  /* padding-bottom: 1vh; */
}

.active_text {
  font-size: 16px;
  font-weight: 700;
}

.non_active_text {
  font-size: 16px;
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}

.Accordion_Style {
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid var(--LIGHT_GRAY_COLOR);
  border-radius: 4px;
}

.Accordion_Header {
  width: 100%;
  background-color: var(--LIGHT_GRAY_COLOR);
  padding: 20px 20px 16px 20px;
  display: flex;
}

.id_style {
  font-size: 24px;
  line-height: 24px;
  word-break: break-all;
}

.small_text {
  font-size: 12px;
  line-height: 16px;
}

.gray_text {
  color: var(--DARK_GRAY_COLOR) !important;
}

.items_count_style {
  background-color: var(--WHITE_COLOR);
  border: 1px solid var(--DARK_GREEN_COLOR);
  width: fit-content;
  padding: 6px 10px;
  border-radius: 54px;
  color: var(--OLIVE_GREEN_COLOR);
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.ms-12 {
  margin-left: 12px;
}

.Accordion_Body {
  width: 100%;
  background-color: var(--WHITE_COLOR);
  padding: 0px 20px;
  /* max-height: 340px; */
  max-height: 46vh;
  overflow-y: auto;
}
@media screen and (min-height: 600px) and (max-height: 700px) {
  .Accordion_Body {
    max-height: 30vh;
  }
}
@media screen and (min-height: 700px) and (max-height: 800px) {
  .Accordion_Body {
    max-height: 36vh;
  }
}
.cust_info {
  min-height: 46px;
  align-items: center;
  background: #f7f7f7;
}

.medium_text {
  font-size: 14px;
  line-height: 20px;
}

.large_text {
  font-size: 16px;
  line-height: 24px;
}

.phone_text_style {
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
  color: var(--OLIVE_GREEN_COLOR);
  width: 98px;
}

.list_header {
  padding-top: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--MEDIUM_LIGHT_GRAY);
}

.list_footer {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px !important;
  border-top: 1px solid var(--MEDIUM_LIGHT_GRAY);
}

.Accordion_Footer {
  width: 100%;
  background-color: var(--LIGHT_GRAY_COLOR);
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

/* .Accordion_Footer_Lower_Screen{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
} */

@media screen and (min-width:992px) and (max-width: 1074px) {
  .Accordion_Footer_Lower_Screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
@media screen and (min-width:1138px) and (max-width: 1274px) {
  .Accordion_Footer_Lower_Screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
@media screen and (min-width:992px) and (max-width: 1137px) {
  .Accordion_Footer_Lower_Screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .edit-order-reset-button-alignment{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

}

.success-btn {
  background-color: var(--DARK_GREEN_COLOR);
  color: var(--WHITE_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
  max-width: 99px;
}

.success-btn-modal {
  background-color: var(--DARK_GREEN_COLOR) !important;
  color: var(--WHITE_COLOR) !important;
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}
.success-btn-modal-disabled {
  background-color: var(--MEDIUM_LIGHT_GRAY);
  color: var(--WHITE_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}

.success-border-btn {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background-color: var(--WHITE_COLOR);
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}
.card-grid {
  display: grid;
  grid-template-columns: 1fr 5fr;
  background: var(--LIGHT_GRAY_COLOR);
  border-radius: 0.293vw;
}
.logo-img {
  display: flex;
  width: 2.196vw;
  height: 2.196vw;
  margin-left: 1.464vw;
  margin-top: 1.464vw;
  font-size: 2.196vw;
}
.card-order-number {
  font-size: 1.757vw;
  line-height: 2.05vw;
  letter-spacing: 0.013vw;
  margin: 0;
  margin-top: 0.512vw;
}

.cancelled-item-number {
  display: inline-block;
  background: var(--WHITE_COLOR);
  border: 1px solid var(--DARK_GREEN_COLOR);
  box-sizing: border-box;
  border-radius: 3.953vw;
  font-size: 0.878vw;
  line-height: 1.171vw;
  letter-spacing: 0.4px;
  text-decoration-line: line-through;
  text-align: center;
  color: var(--OLIVE_GREEN_COLOR);
  margin: 0;
  margin-top: 0.512vw;
}
.card-btn-wrapper {
  margin-top: 2.928vw;
  padding-bottom: 1.464vw;
  margin-left: 97px;
}
.card-btn-wrapper .view-btn {
  width: 5.783vw;
  height: 3.221vw;
  background: var(--DARK_GREEN_COLOR);
  border-radius: 0.173vw;
  font-size: 1.318vw;
  line-height: 1.171vw;
  letter-spacing: 0.092vw;
  text-transform: capitalize;
  color: var(--WHITE_COLOR);
  text-align: center;
  border: none;
  margin-left: 16px;
}
.card-btn-wrapper .dismiss-btn {
  width: 7.906vw;
  height: 3.221vw;
  background: #f7f7f7;
  border: 0.073vw solid var(--DARK_GREEN_COLOR);
  box-sizing: border-box;
  border-radius: 0.173vw;
  font-size: 1.318vw;
  line-height: 1.171vw;
  letter-spacing: 0.092vw;
  text-transform: capitalize;
  color: var(--DARK_GREEN_COLOR);
  text-align: center;
}
.white_background {
  background-color: var(--WHITE_COLOR);
}
.normal-font {
  font-size: 1rem !important;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
}

/* Just to override bootstrap btn */
.order-help-dropdown .btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: var(--DARK_GREEN_COLOR);
  background-color: var(--WHITE_COLOR);
  border-color: var(--DARK_GREEN_COLOR);
}
.order-help-dropdown .btn-primary:hover {
  color: var(--DARK_GREEN_COLOR);
  background-color: var(--WHITE_COLOR);
  border-color: var(--DARK_GREEN_COLOR);
}
.order-help-dropdown .btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: var(--DARK_GREEN_COLOR);
  background-color: var(--WHITE_COLOR);
  border-color: var(--DARK_GREEN_COLOR);
}
.order-help-dropdown .dropdown-item:focus,
.dropdown-item:hover {
  color: var(--DARK_GREEN_COLOR);
  background-color: var(--WHITE_COLOR);
}
.order-help-dropdown .btn-primary {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background-color: var(--WHITE_COLOR);
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}
.order-help-dropdown .btn {
	border: 1px solid var(--DARK_GREEN_COLOR);
	/* background-color: var(--WHITE_COLOR); */
	background-color: #F7F7F7;
	color: var(--DARK_GREEN_COLOR);
	border-radius: 4px;
	font-size: 18px;
	line-height: 16px;
	padding: 14px 18px;
}
.order-help-dropdown .btn-primary:hover {
	color: var(--DARK_GREEN_COLOR);
	/* background-color: var(--WHITE_COLOR); */
	background-color: #F7F7F7;
	border-color: var(--DARK_GREEN_COLOR);
}
.order-help-dropdown-menu {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  padding-left: 1.25rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.order-help-dropdown-menu-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--OLIVE_GREEN_COLOR);
  outline: none !important;
  /* padding-right: 3rem; */
}
.order-support-number {
  font-family: Roboto Regular, sans-serif;
  color: var(--OLIVE_GREEN_COLOR) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 2.125rem;
  line-height: 2.25rem;
  letter-spacing: 0.25px;
}
.modal-footer {
  border: none;
}
.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}
.contact-supp-hr {
  color: #999999;
  opacity: 1;
  margin-top: 0.8rem !important;
}

/* SVTOrderAcceptPopup css */
.accept-order-info-popup {
  background: #f7f7f7;
  border-top: 1px solid #007f00;
  border-bottom: 1px solid #007f00;
  width: 100%;
  padding: 0.5rem 1rem;
}
.histoy-table-height::-webkit-scrollbar,
.no-summary-height::-webkit-scrollbar,
.fixed_block::-webkit-scrollbar,
.Accordion_Body::-webkit-scrollbar,
.menu-table::-webkit-scrollbar {
  border-radius: 5px;
  width: 4px;
}

/* #region CSS_for_Notification */
.position-top-right {
  position: fixed;
  right: 0vw;
  top: 0vh;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 50vw;
  max-width: 50vw;
  box-shadow: 0px 0px 10px rgb(156, 152, 147);
  background-color: rgb(253, 255, 255);
  transition: 100ms;
}

.notification {
  min-height: 10vh;
  max-height: 10vh;
  position: relative;
  top: 3vh;
  font-size: large;
  left: 3vw;
  max-width: 90%;
  align-items: center;
  padding-top: 1.1vh;
  color: #000000;
}
.btn-mine {
  position: absolute;
  top: -1vh;
  right: 0vw;
  color: #229c0a;
}

.border-below {
  border-bottom: 1px solid #999999;
}
.white_text {
  color: var(--WHITE_COLOR);
}
.driver_details {
  margin-left: auto;
  width: 201px;
}
.font_darkgray_color {
  color: var(--DARK_GRAY_COLOR);
  letter-spacing: 0.5px;
}
.driver_details_margin {
  margin-right: 10px;
}
.cancel_order_header {
  padding: 14px 20px;
  height: 75px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 11px;
  background: var(--WHITE_COLOR);
  border-radius: 8px;
  color: var(--DARK_GREEN_COLOR);
  border: 1px solid var(--DARK_GREEN_COLOR);
}
/* CSS FOR ORDER HISTORY  */
.order-history-order-details-container {
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0px 0px;
}
.order-history-footer {
  background: #f7f7f7;
  padding-top: 20px;
  padding-bottom: 20px;
}
.printer-icon-order-history {
  padding: 0px;
  width: 44px;
  height: 44px;
  margin-left: 24px;
  background: #f7f7f7;
  border: 1px solid #007f00;
  box-sizing: border-box;
  border-radius: 4px;
}

.active-main-header-link {
  font-weight: bold;
  border-bottom: 3px solid var(--YELLOW_COLOR);
  width: fit-content;
}
.green-text {
  color: var(--DARK_GREEN_COLOR);
}
.green-text:hover {
  color: var(--DARK_GREEN_COLOR);
}
.max-width-75 {
  max-width: 75px;
}
.modifier-plus-icon {
  margin-top: 10px;
}
.order-accept-popup {
  padding-left: 10px;
  text-decoration: none;
  color: var(--OLIVE_GREEN_COLOR);
}
.notification-text {
  position: absolute;
  width: 105px;
  height: 21px;
  left: 40px;
  top: 41px;

  /* Heading/H6 Regular 18 */

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #000000;
}
.close-button-green {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 44px;
  height: 44px;
  right: 64px;
  top: 28px;

  /* Primary/Green */

  background: #007f00;
  border-radius: 4px;
}

.bg-topDark {
  position: absolute;
  width: 673px;
  height: 156px;
  left: 0px;
  top: 0px;
  background: #f7f7f7;
}

.active-class-text-notif {
  border-bottom: #ffe600 4px solid;
  width: fit-content;
  /* Primary/Yellow */
}

.recent-block {
  position: relative;
  height: 24px;
  left: 39px;
  top: 110px;
  bottom: 22px;
  width: 90px;
}

.recent-text {
  position: relative;
  width: 53px;
  height: 24px;
  left: 39px;
  top: 110px;
  bottom: 22px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  letter-spacing: 0.5px;

  color: #000000;
}

.recent-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;

  position: static;
  width: 27px;
  height: 28px;
  left: 63px;
  top: 0px;

  /* Primary/Light Green */

  background: #99cc00;
  border-radius: 54px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}
.recent-pill-text {
  position: static;
  width: 22px;
  height: 22px;
  left: 20px;
  top: 6px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}

.all-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;

  position: static;
  width: 34px;
  height: 28px;
  left: 30px;
  top: 0px;

  /* Background Tints/Pale grey 2 */

  background: #ebebeb;
  border-radius: 54px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 10px;
}

.all-pill-text {
  position: static;
  width: 22px;
  height: 22px;
  left: 10px;
  top: 6px;

  /* Caption/Caption Regular 12 */

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px;

  /* Supporting Colors/Dark Green */

  color: var(--OLIVE_GREEN_COLOR);

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.clear-all-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 18px;

  position: absolute;
  width: 113px;
  height: 44px;
  right: 64px;
  top: 94px;

  /* Background Tints/Pale grey 1 */

  background: #f7f7f7;
  /* Primary/Green */

  border: 1px solid #007f00;
  box-sizing: border-box;
  border-radius: 4px;
}

.clear-all-text {
  position: relative;
  width: 84px;
  height: 16px;
  left: -11px;
  top: -2px;

  /* Button/Button Bold 18 */

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  /* identical to box height, or 89% */

  letter-spacing: 1.25px;
  text-transform: capitalize;

  /* Primary/Green */

  color: #007f00;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.time {
  position: relative;
  width: 76px;
  height: 16px;
  left: 42px;
  top: 0px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #666666;
}

.text-custom {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.text-eta {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  text-align: right;

  /* Text Color - black */

  color: #000000;
}

.grid-notif-single {
  display: grid;
  grid-template-rows: 3vh 8vh 2vh;
}

.notification-align-custom {
  position: relative;
  top: 4vh;
  left: 3vw;
  padding-bottom: 0.5rem;
}

.grid-notif-message {
  display: grid;
  position: relative;
  left: 23px;
  width: 587px;
  grid-template-columns: 10% 62% 23% 5%;
}

.grid-notif-two {
  display: grid;
  grid-template-rows: 60% 40%;
}

.scroller {
  margin-top: 156px;
  max-height: 612px;
  overflow-y: scroll;
}

.scroller::-webkit-scrollbar {
  width: 0.313rem;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
}
.text-link-none {
  text-decoration: none;
}

/* #endregion  CSS_for_Notification */

.white-text {
  color: var(--WHITE_COLOR);
}
.driver-details {
  margin-left: auto;
  width: 203px;
}
.font-darkgray-color {
  color: var(--DARK_GRAY_COLOR);
  letter-spacing: 0.5px;
}
.driver-details-magine {
  margin-right: 8px;
  align-self: center;
}
.cancel-order-header {
  padding: 14px 20px;
  height: 75px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 11px;
  background: var(--WHITE_COLOR);
  border-radius: 8px;
  color: #004f00;
  border: 1px solid var(--OLIVE_GREEN_COLOR);
}

/* #region PrintReciept */
.component-to-print {
  display: none;
}
.success_btn {
  border: 1px solid #007f00;
  background-color: #007f00;
  color: #ffffff;
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}

.success_border_btn {
  border: 1px solid #007f00;
  background-color: #ffffff;
  color: #007f00;
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}

.receipt_style {
  background-color: #ffffff;
  padding: 30px 20px 90px 20px;
  width: 302px;
  min-height: 100px;
}

.extra_small_text {
  font-size: 11px;
  line-height: 15px;
  color: #000000;
}

.extra_large_text {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  color: #000000;
}

.receipt_address_style {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  padding-bottom: 10px;
}

.gray_solid_border_top {
  border-top: 2px solid rgba(0, 0, 0, 0.25);
}

.gray_dashed_border_top {
  border-top: 2px dashed rgba(0, 0, 0, 0.25);
}

.font_bold {
  font-weight: 700;
}
/* #endegion  PrintReciept */
.order-history-table {
  /* height: 100%; */
  /* min-height: 69vh; */
  left: 40px;
  padding-bottom: 20px;
  margin-top: 25px;
  background: var(--WHITE_COLOR);
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0px 0px;
}
.history-table-content {
  border: 2px solid var(--LIGHT_GRAY_COLOR);
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 20px;
}

.histoy-table-height::-webkit-scrollbar,
.no-summary-height::-webkit-scrollbar,
.fixed_block::-webkit-scrollbar,
.Accordion_Body::-webkit-scrollbar,
.menu-table::-webkit-scrollbar {
  border-radius: 5px;
  width: 4px;
}
/* ::-webkit-scrollbar{
  background-color: transparent;
  border-radius: 10px;
} */
.histoy-table-height::-webkit-scrollbar-thumb,
.no-summary-height::-webkit-scrollbar-thumb,
.menu-table::-webkit-scrollbar-thumb,
.fixed_block::-webkit-scrollbar-thumb,
.Accordion_Body::-webkit-scrollbar-thumb {
  background: var(--SEA_MIST_COLOR);
  border-radius: 10px;
}

.histoy-table-height {
  min-height: 58vh;
  max-height: 257px;
  overflow-y: auto;
}
.no-summary-height{
	min-height: 58vh;
	max-height: 257px;
	overflow-y: auto;
  }
.histoy-table-margin {
  margin-left: 20px;
  /* width: 255px; */
}
.history-table-header-Order {
  margin-left: 20px;
  width: 255px;
}
.underline {
  text-decoration: underline;
}
.histoy-table-margin-last7 {
  margin-left: 10px;
}
.history-table-header {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.search-field {
  width: 281px;
  height: 44px;
  margin-top: 10px;
  background: var(--WHITE_COLOR);
  border: 1px solid var(--SEA_MIST_COLOR);
  box-sizing: border-box;
  border-radius: 4px;
}
.search-icon {
  width: 24px;
  height: 24px;
  margin-left: 247px;
  padding-top: 22px;
  display: flex;
  align-items: center;
}
.search-padding {
  padding-left: 20px;
  padding-right: 11px;
}
.filter-button.btn-primary {
  height: 44px;
  margin-left: 17px;
  margin-top: 10px;
  /* width: 110px; */
  background: var(--DARK_GREEN_COLOR);
  color: var(--WHITE_COLOR);
  padding: 14px 18px;
  outline: none;
  border: none;
  box-shadow: none;
}
.product-list-container {
  display: grid;
  grid-template-columns: 7fr 2fr 1fr;
}
.alphabet-button.btn-primary {
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: transparent;
  color: #004F00;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 24px;
}
.alphabet-button-active.btn-primary {
  background-color: #BEDBBF;
  color: #004F00;
  border: 1px solid #007F00;
  border-radius: 4px;
  box-sizing:border-box;
}
.disabled-alphabet {
  color:#999999 !important
}
.alphabet-button.focus, .alphabet-button:focus {
  -webkit-box-shadow: none!important;
  box-shadow: none!important;
  outline: 0;
}
.alphabet-card {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-top: 20px;
  width: 270px;
  background-color: #EBEBEB;
  border-radius: 4px;
  border: none;
}
.alphabet-card-body{
  padding:15px;
}
.histoy-table-time {
  text-transform: uppercase;
  color: var(--DARK_GRAY_COLOR);
}
.history-btn-alignment {
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 10px;
}
.search-by-orderID {
  width: 281px;
  height: 44px;
  margin-top: 10px;
  border: 1px solid #bedbbf;
  border-radius: 4px;
}
.search-by-orderID:focus-within {
  width: 281px;
  height: 44px;
  margin-top: 10px;
  border: 1px solid #99cc00;
  border-radius: 4px;
}
.border-highlight {
  width: 281px;
  height: 44px;
  margin-top: 10px;
  border: 1px solid #99cc00;
  border-radius: 4px;
}

.order-history-summary-container {
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px 20px 0px 20px;
}
.order-history-summary-container-altered {
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px 20px 10px 20px;
}
.summary-space-last {
  padding-right: 10px;
}
.order-history-summary-container .summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 20px;
	padding-right: 20px;
	padding-left: 20px; */
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 20px;
	padding-right: 20px;
	padding-left: 20px; */
  padding-bottom: 10px;
}
.order-history-summary-container > hr {
  display: block;
  position: relative;
  margin: 0px;
  height: 0;
  width: 100%;
  max-height: 0;
  font-size: 1px;
  line-height: 0;
  clear: both;
  border: 1px solid #999999;
}

.order-history-summary-container .summary-content-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 5%;
  align-items: start;
  padding-top: 20px;
  padding-bottom: 20px;
}

.order-history-summary-container .summary-content-grid .orders-details-flex {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  /* padding-left: 20px; */
}

.summary-content-grid .orders-details-flex {
  font-size: 1.3vw;
  /* line-height: 24px; */
  letter-spacing: 0.5px;
  color: #000000;
}
.content-header {
  /* font-family: "Roboto Regular"; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.summary-content-grid .orders-details-flex .summary-content-values {
  letter-spacing: -0.5px;
  color: var(--OLIVE_GREEN_COLOR);
  font-size: 60px;
  line-height: 72px;
  word-break: break-all;
  font-family: "Roboto Light", sans-serif;
}

.summary-content-grid .orders-details-flex .summary-content > p {
  margin: 0 !important;
}
.olivegreen-color-text {
  color: var(--OLIVE_GREEN_COLOR);
}
.cancelled-content {
  font-size: 1.3vw;
  flex-direction: column-reverse;
  letter-spacing: 0.5px;
  color: #000000;
}
.cancelled-history-margin {
  margin-left: 100px;
}
.cancelled-history-spacing {
  margin-bottom: 12px;
}
.history-search {
  width: 141px;
  border: none;
  height: 40px;
  padding-left: 20px;
}

/* Notification css */
.gray-bg {
  background-color: #f7f7f7;
}
.gray-outline-btn {
  background: #f7f7f7;
  border: 1px solid #007f00;
  box-sizing: border-box;
  border-radius: 4px;
}
.gray-outline-btn-txt {
  border: 1px solid var(--DARK_GREEN_COLOR);
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  width: 113px;
  height: 44px;
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 1.25px;
}

.pale-grey-bg {
  background-color: #ebebeb;
}
.notification-box {
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
}
.offcanvas {
  transition: transform 0.3s ease-in;
}
.offcanvas-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}
.maxWidth70 {
  max-width: 70px;
}
@media screen and (min-width: 750px) and (max-width: 1050px) {
  .notification-box {
    min-width: 550px;
    max-width: 550px;
  }
}
@media screen and (min-width: 1051px) {
  .notification-box {
    min-width: 672px;
    max-width: 672px;
  }
}
.filter-grid {
  display: grid;
  grid-template-columns: 0.6fr 1fr 1fr;
  justify-items: stretch;
}
.filter-grid-dates {
  display: grid;
  grid-template-columns: 0.6fr 2fr;
  justify-items: stretch;
}
.row-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 40%;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

/* .form-group label:before {
	content: '';
	-webkit-appearance: none;
	background-color: transparent;
	border: 0.125rem solid var(--OLIVE_GREEN_COLOR);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
		inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 0.625rem;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 0.313rem;
}

.form-group input:checked + label:after {
	content: '';
	display: block;
	position: absolute;
	top: -0.625rem;
	left: 0.938rem;
	width: 0.563rem;
	height: 1.625rem;
	border: solid var(--OLIVE_GREEN_COLOR);
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
} */

.filter-modal-footer {
  padding-top: 15px;
  border-top: 1px solid #999999;
}
.filter-modal-footer-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
}

.reset-btn {
  width: 5.438rem;
  height: 2.75rem;
  background: #f7f7f7;
  border: 1px solid #007f00;
  /* box-sizing: border-box; */
  border-radius: 4px;
  font-size: 1.125rem;
  /* line-height: 1rem; */
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #007f00;
  padding: 0rem;
  margin-left: 20px;
}

.cancel-btn {
  width: 6.125rem;
  height: 2.75rem;
  background: #f7f7f7;
  border: 1px solid #007f00;
  box-sizing: border-box;
  border-radius: 0.25rem;
  font-size: 1.125rem;
  line-height: 1rem;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #007f00;
  margin-right: 1rem;
}

.apply-btn {
  width: 5.375rem;
  height: 2.75rem;
  background: #007f00;
  border-radius: 0.25rem;
  font-size: 1.125rem;
  line-height: 1rem;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
  border: #007f00;
  margin-right: 1.25rem;
}

.form-check-input:checked {
  background: var(--OLIVE_GREEN_COLOR);
  transform: matrix(0, -1, -1, 0, 0, 0);
  border: 2px solid #ffffff;
  outline: 1px solid var(--OLIVE_GREEN_COLOR);
}
.form-check-input:checked[type="radio"] {
  background-image: none;
  width: 24px;
  height: 24px;
}
.form-check-input {
  width: 24px;
  height: 24px;
  margin-top: 0px;
  border: 2px solid #ffffff;
  outline: 1px solid var(--OLIVE_GREEN_COLOR);
}

.date-btn {
  width: 8.563rem;
  height: 2.75rem;
  background: #f7f7f7;
  border: 1px solid #007f00;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1rem;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #007f00;
}

.test-grid {
  display: grid;
  grid-template-columns: 0fr 0.42fr 2fr;
}
.loaderSpinner > div:first-child {
  z-index: 100;
  position: fixed !important;
}
#histoy_filter_popup.modal-header {
  border-bottom: 1px solid #999999;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 0px 15px 0px;
}
#partner-filter.filter-grid {
  margin-bottom: 26px;
}
#history-filter-body {
  padding: 20px;
}
.round-button {
  min-width: 100px;
  /* max-width: 120px; */
  text-decoration: none;
  display: inline-block;
  outline: none;
  cursor: pointer;
  border-style: none;
  color: #004f00;
  background-color: var(--WHITE_COLOR);
  /* border-radius: 40%; */
  overflow: none;
  text-align: center;
  padding: 0;
  border-radius: 35px !important;
  height: 28px;
  border: 1px solid var(--DARK_GREEN_COLOR);
}
.round-button:before {
  content: "";
  padding-top: 32%;
}
#but_ride {
  /* max-width: 90%; */
  float: left;
  padding-left: 10px;
  text-transform: capitalize;
  font-size: 12px;
}
.round-button:active {
  background-color: #2980b9;
}
/* #but_ride::after {
	content: 'X';
	float: right;
	display: contents;
} */
.list-group {
  display: grid;
}

.list-group.list-group-flush .btn.btn-primary {
  /* border-bottom: 1px solid gray; */
  background-color: white;
  border: none;
  color: black;
  border-bottom: 1px solid var(--LIGHT_GRAY_COLOR);
  height: 77px;
}
.text-alignment {
  text-align: left;
}

.histoy-content-margin {
  margin-left: 10px;
  width: 258px;
  word-break: break-all;
}
.order-id {
  word-break: break-word;
  /* padding-top: 0.5rem; */
}
.r-width {
  margin-right: 13px;
}
.pause-incoming-orders-others-popup {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background: #ffffff;
  border: 1px solid #bedbbf;
  box-sizing: border-box;
  border-radius: 4px;
}
.success-btn-disable {
  background-color: var(--MEDIUM_LIGHT_GRAY);
  color: var(--WHITE_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}
.cancel-store-notification {
  background: #f7f7f7;
  border-top: 1px solid #ff9900;
  border-bottom: 1px solid #ff9900;
  width: 100%;
  padding: 0.5rem 1rem;
}
.green-outline-btn {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background-color: #f7f7f7;
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}
.green-outline-btn:active {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background-color: #f7f7f7;
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}

/*Help Section*/
.padding-left-right-50 {
  padding: 0px 50px;
}
.help-shadow {
  position: relative;
  top: 30px;
  /* padding: 0px 40px; */
  /* max-height: 90vh;
	min-height: 88vh; */
  overflow-y: scroll;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0px 20px;
}
/* @media screen and (max-height: 765px){
	.help-shadow{
		max-height: 39rem;
	}
}
*/

@media screen and (min-height: 1080px) {
  .help-shadow {
    max-height: 88vh;
    min-height: 88vh;
  }
}
@media screen and (max-height: 765px) and (min-height: 720px) {
  .help-shadow {
    max-height: 82vh;
    min-height: 79vh;
  }
}
@media screen and (max-height: 666px) and(min-height:607px) {
  .help-shadow {
    max-height: 79vh;
    min-height: 79vh;
  }
}
@media screen and (max-height: 606px) and(min-height:583px) {
  .help-shadow {
    max-height: 77vh;
    min-height: 77vh;
  }
}
@media screen and (max-height: 683px) and (min-height: 667px) {
  .help-shadow {
    max-height: 80vh;
    min-height: 80vh;
  }
}
@media screen and (max-height: 719px) and (min-height: 684px) {
  .help-shadow {
    max-height: 81vh;
    min-height: 81vh;
  }
}
@media screen and (max-height: 826px) and (min-height: 766px) {
  .help-shadow {
    max-height: 83vh;
    min-height: 83vh;
  }
}
@media screen and (max-height: 900px) and (min-height: 827px) {
  .help-shadow {
    max-height: 84vh;
    min-height: 84vh;
  }
}
@media screen and (max-height: 954px) and (min-height: 900px) {
  .help-shadow {
    max-height: 85vh;
  }
}
@media screen and (max-height: 1079px) and (min-height: 1000px) {
  .help-shadow {
    min-height: 87vh;
  }
}
@media screen and (max-height: 999px) and (min-height: 955px) {
  .help-shadow {
    min-height: 86vh;
    max-height: 86vh;
  }
}
.help-shadow::-webkit-scrollbar {
  width: 4px;
}
.help-shadow::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
.help-shadow::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
  border-radius: 5px;
}
.input-group-help {
  width: 40vw;
  margin-left: 10px;
  margin-right: 10px;
}
.help-input-shadow {
  background: #ffffff;
  border: 1px solid #bedbbf;
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  /* width: 600px;
	left: 343px; */
  top: 126px;
  max-height: 148px;
}

.text-help-section {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  color: #004f00;
}
.help-input-box-shadow {
  background: #ffffff;
  border: 1px solid #99cc00;
  box-sizing: border-box;
  position: absolute;
  /* width: 600px;
	left: 343px; */
  top: 126px;
  z-index: 2;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.padding-bottom-30PX {
  padding-bottom: 30px;
  padding-top: 40px;
}
.form-control:focus {
  box-shadow: 0px 0px 0px inset, 0px 0px 0px;
}
.input-help {
  height: 44px;
  border: none;
  outline: none;
  box-shadow: none;
  width: 48vw;
  /* margin-right: 10px; */
  margin-left: 10px;
  padding: 0px;
}
.help-image-margin-right {
  padding-left: 10px;
}
.border-help-bottom {
  border-bottom: 1px solid black;
}
.search-bar-bottom-border {
  border-bottom: 1px solid #999999;
}
.search-li-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  list-style-type: none;
  margin-left: -12px;
  margin-right: 10px;
  padding-bottom: 15px;
  margin-top: 13px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}
.search-li-text-no-border {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  list-style-type: none;
  margin-left: -12px;
  margin-right: 10px;
  padding-bottom: 15px;
  margin-top: 13px;
  cursor: pointer;
}
.border-below-accordion {
  border-bottom: 1px solid #bedbbf;
  margin: 20px 0px;
}
.help-list {
  /* max-height: 105px;
  overflow-y: scroll; */
  margin-bottom: 0px;
}
.help-list::-webkit-scrollbar {
  width: 0.313rem;
}
.help-list::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
  border-radius: 5px;
}

.button-array {
  /* position: absolute;
	top:30vh; */
  display: flex;
  position: relative;
  top: 50px;
  padding-left: 4px;
  z-index: 0;
  max-width: 95vw;
  overflow-x: scroll;
  margin-right: -20px;
}
.button-array::-webkit-scrollbar {
  display: none;
}
.category-buttons {
  min-width: 209px;
  height: 76px;
  left: 432px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: none;
  margin: 0px 30px 0px 0px;
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #004f00;
  padding: 0px 34px;
}
.category-buttons:focus {
  background: #bedbbf;
  outline: 0;
}
.category-buttons-focus {
  background: #bedbbf;
  outline: 0;
}

.help-header-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #007f00;
}
.help-search-result-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #666666;
  margin-top: 40px;
  margin-bottom: 20px;
}
.pos-div {
  position: relative;
  top: 40px;
}
.pos-top {
  position: relative;
  top: 20px;
}

.hot-drink-padding {
  padding-left: 0px;
  padding-right: 0px;
}
.hot-icon {
  padding-left: 19px;
}
.order-help-alignment {
  padding-left: 50px;
}

/* css for login page */
.login-page-container {
  background: url("../src/images/blur-login-bg.png") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-width: 100%;
  min-height: 100%;
  backdrop-filter: blur(15px);
  z-index: -99;
}

.login-page-title {
  position: absolute;
  background: rgba(196, 196, 196, 0.8);
  backdrop-filter: blur(15px);
  width: 100%;
  height: 242px;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.login-form {
  position: absolute;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 36.213vw;
  height: 80vh;
  max-width: 510px !important;
  max-height: 598px;
  z-index: 5;
  right: 0;
  backdrop-filter: blur(15px);
}
.login-txt {
  font-weight: 300;
  font-size: 3.75rem;
  line-height: 4.5rem;
  letter-spacing: -0.5px;
  color: var(--OLIVE_GREEN_COLOR);
}
.login-form-input {
  display: block;
  width: 30.684vw;
  height: 44px;
  max-width: 450px !important;
  border: 1px solid #bedbbf;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
}
.login-submit-btn {
  display: block;
  width: 30.684vw;
  max-width: 450px !important;
  height: 44px;
  background: #007f00;
  border: 4px solid #007f00;
  border-radius: 4px;
}
.login-page-title-header {
  font-size: 3rem;
  line-height: 3.5rem;
  color: var(--OLIVE_GREEN_COLOR);
}
.login-page-title-body {
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 0.18px;
  color: var(--OLIVE_GREEN_COLOR);
}
.login-page-label {
  font-size: 1.125rem;
  line-height: 1.313rem;
  letter-spacing: 0.15px;
  color: #666666;
}
@media (max-width: 900px) {
  .login-page-container {
    font-size: 10px !important;
  }
  .login-txt {
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 2.5rem;
    letter-spacing: -0.5px;
    color: var(--OLIVE_GREEN_COLOR);
  }
  .login-page-label {
    font-size: 0.7rem;
    line-height: 1rem;
    letter-spacing: 0.15px;
    color: #666666;
  }
  .login-page-title-header {
    font-size: 2rem;
    line-height: 3.5rem;
    color: var(--OLIVE_GREEN_COLOR);
  }
  .login-page-title-body {
    font-size: 0.5rem;
    line-height: 1.75rem;
    letter-spacing: 0.18px;
    color: var(--OLIVE_GREEN_COLOR);
  }
}
.form-control:focus {
  box-shadow: 0px 0px 0px inset, 0px 0px 0px;
}
.status-letter-spacing {
  letter-spacing: 0.3px;
  word-break: break-word;
}

.help-para-margin {
  position: relative;
  left: 7.14%;
  right: 15.89%;
  top: 0%;
  bottom: 0%;
  margin-right: 218px;
  padding-top: 20px;
  font-family: "Roboto regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  text-align: justify;
}
.accordion-view-padding-right-10 {
  padding-right: 10px;
}

.order-filter-date-dropdown {
  width: 137px;
  height: 44px;
  background: #f7f7f7;
  border: 1px solid #007f00;
  box-sizing: border-box;
  border-radius: 4px;
}
.order-filter-date-dropdown[disabled] {
  width: 137px;
  height: 44px;
  background: #f7f7f7;
  border: 1px solid #007f00;
  box-sizing: border-box;
  border-radius: 4px;
  opacity: 1;
}
.filter-rounded-btn-container {
  height: 48px;
  background: #f7f7f7;
  border-radius: 4px;
  margin-left: 20px !important;
  margin-right: 20px;
  margin-bottom: 10px;
}
.setting-button {
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.min-letter-spacing {
  letter-spacing: -0.011em;
}
/* bugs */
.padding-header {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.header-shadow {
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
}
.order-list-item-margin {
  margin-right: 3vw;
}
.eta-padding {
  margin-right: 13px;
}
.img-orderId-space {
  margin-right: 10px;
}
.summary-header-padding-right10 {
  margin-right: 10px;
}
.last-7-days {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}
.dark-shade-gray {
  color: #666666;
}
.sorting-button-margin {
  margin-left: 12px;
}
.icon-margin-left {
  margin-left: -1vw;
}
.order-list-padding-left20 {
  padding-left: 20px;
}
.margin-top-live-order {
  margin-top: 30px;
}
.capital_letter {
  text-transform: uppercase;
}
.cancel-store-notification-header {
  background: #f7f7f7;
  border-top: 1px solid #ff9900;
  border-bottom: 1px solid #ff9900;
  padding: 0.5rem 1rem;
}
.green-link {
  text-decoration: none;
  color: #004f00;
}
.green-link:hover {
  color: #004f00;
}
.live-order-order-list-container {
  min-height: 80vh;
}
@media screen and (max-height: 900px) and (min-height: 668px) {
  .live-order-order-list-container {
    min-height: 10vh;
  }
  .order_list_data {
    max-height: 60vh;
    overflow-y: auto;
  }
  .order_table_container {
    margin: 20px;
    margin-top: 22px;
    border: 2px solid var(--LIGHT_GRAY_COLOR);
    box-sizing: border-box;
    border-radius: 4px;
    min-height: 73vh;
  }
}
@media screen and (max-height: 667px) {
  .fixed_block {
    max-height: 71vh;
  }
  .live-order-order-list-container {
    min-height: 80vh;
    max-height: 80vh;
  }
  .order_list_data {
    max-height: 56vh;
    min-height: 56vh;
  }
  .order_table_container {
    min-height: 68vh;
    max-height: 68vh;
  }
  .card_block {
    max-height: 80vh;
    min-height: 80vh;
  }
}
@media screen and (min-height: 1100px) {
  .live-order-order-list-container {
    min-height: 80vh;
  }
  .order_list_data {
    max-height: 78vh;
    overflow-y: auto;
  }
  .order_table_container {
    margin: 20px;
    margin-top: 22px;
    border: 2px solid var(--LIGHT_GRAY_COLOR);
    box-sizing: border-box;
    border-radius: 4px;
    max-height: 85vh;
    min-height: 85vh;
  }
}

.edit-order-checkbox-margin {
  margin-right: 10px;
}

.edit-order-font-bolder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.edit-order-line-through {
  text-decoration: line-through;
}

.edit-order-input {
  width: 66px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #bedbbf;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  margin-top: -10px;
}

.edit-order-margin-image {
  margin-right: -20px;
}

.edit-order-avl-margin {
  margin-right: 0px;
}
.btn-cancel {
  padding: 14px 18px;
  width: 98px;
  background: #f7f7f7;
  border: 1px solid var(--OLIVE_GREEN_COLOR);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 20px;
  color: var(--DARK_GREEN_COLOR);
}

.btn-save {
  padding: 14px 18px;
  width: 79px;
  background: #999999;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 20px;
  color: #ffffff;
}
.total-price-align {
  padding-left: 40px;
}
.partially-accept-btn {
  background-color: #999999;
  color: var(--WHITE_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}
.accept-store-notification-header {
  background: #f7f7f7;
  border-top: 1px solid #007f00;
  border-bottom: 1px solid #007f00;
  padding: 0.5rem 1rem;
}
.setting-store-notification {
  background: #f7f7f7;
  border-top: 1px solid #ff9900;
  border-bottom: 1px solid #ff9900;
  padding: 0.5rem 1rem;
}

.gray_background_color {
  background-color: #ebebeb;
}
.order-status-green-color {
  color: #007f00;
}
@media screen and (max-height: 700px) {
  .menu-table {
    max-height: 21vh;
    min-height: 21vh;
  }
  .menu-table-container-history {
    min-height: 42vh;
  }
  .menu-table-container-cancelled {
    min-height: 37vh;
  }
  .menu-table-order-history {
    max-height: 28vh;
    min-height: 24vh;
  }
  .menu-table-order-history-cancelled {
    max-height: 25vh;
    min-height: 25vh;
  }
}

@media screen and (min-height: 700px) and (max-height: 800px) {
  .card_block {
    min-height: 83vh;
  }
  .menu-table {
    max-height: 32vh;
  }
}
@media screen and (min-height: 900px) and (max-height: 1000px) {
  .card_block {
    min-height: 87vh;
  }
  .order_table_container {
    max-height: 78vh;
    min-height: 78vh;
  }
}
@media screen and (min-height: 1000px) and (max-height: 1100px) {
  .card_block {
    min-height: 88vh;
  }
  .menu-table-container-history {
    min-height: 64vh;
  }
  .order_list_data {
    max-height: 72vh;
  }
  .order_table_container {
    max-height: 80vh;
    min-height: 80vh;
  }
  .fixed_block {
    max-height: 80vh;
  }
  .menu-table-container-cancelled {
    min-height: 61vh;
  }
  .menu-table-order-history {
    max-height: 55vh;
    min-height: 55vh;
  }
  .menu-table-order-history-cancelled {
    max-height: 53vh;
    min-height: 53vh;
  }
}
@media screen and (min-height: 1100px) {
}
.menu-table-container-history {
  min-height: 66vh;
}
.menu-table-container-cancelled {
  min-height: 63vh;
}
.menu-table-order-history {
  max-height: 57vh;
  min-height: 57vh;
}
.menu-table-order-history-cancelled {
  max-height: 55vh;
  min-height: 55vh;
}
@media screen and (min-height: 1100px) and (max-height: 1200px) {
  .card_block {
    min-height: 90vh;
  }
}
@media screen and (min-height: 1200px) {
  .card_block {
    min-height: 91vh;
  }
  .menu-main-container{
    min-height: 88vh;
  }
  .menu-body-left{
    min-height: 81vh;
    max-height: 81vh;
  }
}

.no-search-results {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
}

.customer-phone {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #004f00;
}
.customer-phone-code-text {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #666666;
}
.customer-phone-code-number {
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: #660099;
}

.reset-button-spacing {
  margin: auto;
  margin-right: 20px;
}

.form-group-spacing-img {
  padding-left: 10px;
}
.form-group-spacing-img-below {
  padding-left: 2px;
}

.modal-custom-width {
  max-width: 606px;
}

.order-id-font-style {
  font-family: "Roboto Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.order-font-history-item-list {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}

.edit-order-cursor-pointer {
  cursor: pointer;
}
/* .edit-order-reset-button-alignment {
  margin-left: auto;
} */

.driver-status-reason {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.5px;

  /* Supporting Colors/Dark Green */

  color: #004f00;
}

.order-history-view-order-btn-back {
  padding: 10px 18px 14px;
  width: 80px;
  height: 44px;
  background: #f7f7f7;
  border: 1px solid var(--DARK_GREEN_COLOR);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 20px;
  color: var(--DARK_GREEN_COLOR);
}

.order-history-item-list-btn-back-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #007f00;
}

.total-text {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding-left: 20vw;
}
.editedBtn {
  display: flex;
  margin-left: auto;
  margin-right: 0;
}

.menu-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-table-container-history {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-height: 1000px) {
  .menu-table-container {
    min-height: 60vh;
    max-height: 60vh;
  }
  .order-history-order-details-container {
    /* min-height: 80vh; */
  }
}
@media screen and (height: 768px) {
  .menu-table-container {
    min-height: 43vh;
    max-height: 43vh;
  }
}
@media screen and (min-height: 1000px) {
  .order-history-order-details-container > .menu-table-container {
    min-height: 64vh;
    max-height: 64vh;
  }
  .parent_block > .order-history-order-details-container {
    /* min-height: 86vh; */
  }
}

.print-component-total {
  margin-left: 42px;
  width: 155px;
}
.order-history-summary-cancelled-container {
  display: grid;
  grid-template-columns: 2fr 24fr;
}

@media screen and (min-width: 1700px) {
  .order-history-summary-cancelled-container {
    display: grid;
    grid-template-columns: 1.5fr 30fr;
  }
  .order-history-price-col {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .modifier-price-col-edit-order {
    flex: 0 0 auto;
    width: 25%;
  }
  .price-dummy-col {
    flex: 0 0 auto;
    width: 25%;
  }
}

.modifier-spacing {
  margin-right: 10px;
}
.margin-top-order-details {
  margin-top: 10px;
}
.modifier-price-col {
  margin-left: -1%;
}
.font-size-14 {
  font-size: 14px;
}
.incoming-order-dropdown {
	/* background-color: var(--LIGHT_GRAY_COLOR) !important; */
	background-color: #F7F7F7;
}

.order-history-filter-spacing {
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
}
.scrollbar-order-setting {
  min-height: 56vh;
  max-height: 56vh;
  margin-right: -20px;
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.scrollbar-order-setting::-webkit-scrollbar {
  width: 0.313rem;
}

.scrollbar-order-setting::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
  border-radius: 5px;
}

@media screen and (height: 768px) {
  .scrollbar-order-setting {
    min-height: 60vh;
  }
}
@media screen and (min-height: 769px) and (max-height: 800px) {
  .scrollbar-order-setting {
    min-height: 60vh;
  }
}
@media screen and (min-height: 800px) and (max-height: 850px) {
  .scrollbar-order-setting {
    min-height: 62vh;
  }
}
@media screen and (min-height: 850px) and (max-height: 870px) {
  .scrollbar-order-setting {
    min-height: 64vh;
  }
}
@media screen and (min-height: 870px) and (max-height: 900px) {
  .scrollbar-order-setting {
    min-height: 66vh;
  }
}
@media screen and (min-height: 900px) and (max-height: 950px) {
  .scrollbar-order-setting {
    min-height: 68vh;
  }
}
@media screen and (min-height: 950px) and (max-height: 1000px) {
  .scrollbar-order-setting {
    min-height: 69vh;
  }
}
@media screen and (min-height: 1000px) and (max-height: 1080px) {
  .scrollbar-order-setting {
    min-height: 71vh;
  }
}
@media screen and (min-height: 1080px) {
  .scrollbar-order-setting {
    min-height: 73vh;
  }
}

.order-setting-footer {
  padding: 20px;
  position: sticky;
  bottom: 0px;
  height: 85px;
  background-color: #f7f7f7;
  margin-left: -20px;
  margin-right: -20px;
}

.padding-left-right-order-setting {
  padding: 0px 50px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.container-order-setting {
  padding-left: 20px;
  padding-right: 20px;
}
.order-setting-spacing-top {
  padding-top: 20px;
  padding-bottom: 20px;
}
.order-setting-bold-text {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.order-setting-normal-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.order-setting-store-details-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #000000;
}
.order-setting-store-details-email-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}
.border-bottom-order-setting {
  padding-bottom: 10px;
  border-bottom: 1px solid #999999;
}
.order-setting-about-spacing {
  padding-bottom: 4px;
}
.order-setting-pause-border {
  border: none;
  padding-top: 4px;
  /* border-top: 1px solid #999999; */
}
.order-setting-log-out-card {
  border: none;
  padding-top: 4px;
  /* border-top: 1px solid #999999; */
  /* border-bottom: 1px solid #999999; */
}
.success-btn-disable-order-setting {
  background-color: var(--MEDIUM_LIGHT_GRAY);
  color: var(--WHITE_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}
.success-btn-order-setting {
  background-color: var(--DARK_GREEN_COLOR);
  color: var(--WHITE_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}
.order-setting-pause-order-card-body {
  padding-bottom: 10px;
}
.chevron-padding-bottom {
  padding-bottom: 20px;
}
.order-setting-chevron-header {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #007f00;
}
.order-setting-card-header {
  padding-bottom: 20px;
  padding-top: 20px;
}
.text-gray-order-setting {
  color: #999999;
}
textarea {
  resize: none;
}

.btn-cancel-text-order-setting {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #007f00;
}
.btn-pause-text-order-setting {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
}
.btn-pause-text-order-setting-success {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
  background: #007f00;
}
.order-setting-store-spacing {
  padding-left: 8px;
}
.notification-card-time {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #666666;
}
.order-id-notification-styling {
  font-weight: bold;
}

.scroller-notification {
  margin-top: 156px;
  max-height: 612px;
  overflow-y: scroll;
}

.scroller-notification::-webkit-scrollbar {
  width: 0.313rem;
}
.scroller-notification::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
  border-radius: 5px;
}
.spacing-scroller {
  position: relative;
  right: 9px;
}
.notification-row-clear-all-spacing {
  padding-bottom: 2px;
}
.maxWidthAuto {
  max-width: auto;
}
.notifications-main-font {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #000000;
}
.notification-header-spacing {
  padding-top: 28px;
  padding-right: 40px;
}
.no-notification-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
}
.edit-order-mod-price-col {
  margin-left: -3.9%;
}
@media screen and (min-width: 1700px) {
  .edit-order-mod-price-col {
    margin-left: -2%;
  }
}

@media screen and (min-height: 1080px) {
	.histoy-table-height {
		min-height: 54vh;
	}
	.no-summary-height{
		min-height: 65.5vh;
	  }
}
@media screen and (max-height: 768px) {
	.histoy-table-height {
		min-height: 35vh;
	}
	.no-summary-height{
		min-height: 51.5vh;
	  }
	.menu-table-container-history {
		min-height: 51vh;
	}
	.menu-table-container-cancelled {
		min-height: 48vh;
	}
	.menu-table-order-history {
		max-height: 40vh;
		min-height: 40vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 37vh;
		min-height: 37vh;
	}
}
@media screen and (max-height: 667px) {
	.histoy-table-height {
		min-height: 25vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 44.5vh;
	}
	.menu-table-container-history {
		min-height: 44vh;
	}
	.menu-table-container-cancelled {
		min-height: 40vh;
	}
	.menu-table-order-history {
		max-height: 31vh;
		min-height: 31vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 28vh;
		min-height: 28vh;
	}
}
@media screen and (min-height: 769px) and (max-height: 800px) {
	.histoy-table-height {
		min-height: 35.5vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 52vh;
	}
	.menu-table-container-history {
		min-height: 50vh;
	}
	.menu-table-container-cancelled {
		min-height: 42vh;
	}
	.menu-table-order-history {
		max-height: 36vh;
		min-height: 38vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 35vh;
		min-height: 35vh;
	}
}
@media screen and (min-height: 800px) and (max-height: 827px) {
	.histoy-table-height {
		min-height: 38vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 54vh;
	}
	.menu-table-container-history {
		min-height: 50vh;
	}
	.menu-table-container-cancelled {
		min-height: 42vh;
	}
	.menu-table-order-history {
		max-height: 36vh;
		min-height: 38vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 35vh;
		min-height: 35vh;
	}
}
@media screen and (min-height: 800px) and (max-height: 827px) {
  .histoy-table-height {
    min-height: 38vh;
    max-height: 1px;
  }
  .menu-table-container-history {
    min-height: 53vh;
  }
  .menu-table-container-cancelled {
    min-height: 48vh;
  }
  .menu-table-order-history {
    max-height: 41vh;
    min-height: 41vh;
  }
  .menu-table-order-history-cancelled {
    max-height: 35vh;
    min-height: 38vh;
  }
}
@media screen and (min-height: 827px) and (max-height: 867px) {
  .histoy-table-height {
    min-height: 40vh;
    max-height: 1px;
  }
  .menu-table-container-history {
    min-height: 55vh;
  }
  .menu-table-container-cancelled {
    min-height: 51vh;
  }
  .menu-table-order-history {
    max-height: 44vh;
    min-height: 44vh;
  }
  .menu-table-order-history-cancelled {
    max-height: 41vh;
    min-height: 41vh;
  }
}
@media screen and (min-height: 827px) and (max-height: 842px){
	.no-summary-height {
	  min-height: 55vh;
	}
  }
  @media screen and (min-height: 843px) and (max-height: 868px){
	.no-summary-height {
	  min-height: 56vh;
	}
  }

@media screen and (min-height: 868px) and (max-height: 900px) {
	.histoy-table-height {
		min-height: 43vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 59vh;
	  }
	.menu-table-container-history {
		min-height: 56vh;
	}
	.menu-table-container-cancelled {
		min-height: 52vh;
	}
	.menu-table-order-history {
		max-height: 45vh;
		min-height: 45vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 42vh;
		min-height: 42vh;
	}
}
@media screen and (min-height: 900px) and (max-height: 927px) {
  .histoy-table-height {
    min-height: 45vh;
    max-height: 1px;
  }
  .menu-table-container-history {
    min-height: 58vh;
  }
  .menu-table-container-cancelled {
    min-height: 54vh;
  }
  .menu-table-order-history {
    max-height: 47vh;
    min-height: 47vh;
  }
  .menu-table-order-history-cancelled {
    max-height: 45vh;
    min-height: 45vh;
  }
}
@media screen and (min-height: 928px) and (max-height: 967px) {
  .histoy-table-height {
    min-height: 46vh;
    max-height: 1px;
  }
  .menu-table-container-history {
    min-height: 59vh;
  }
  .menu-table-container-cancelled {
    min-height: 55vh;
  }
  .menu-table-order-history {
    max-height: 49vh;
    min-height: 49vh;
  }
  .menu-table-order-history-cancelled {
    max-height: 46vh;
    min-height: 46vh;
  }
}
@media screen and (min-height:928px) and (max-height:944px){
	.no-summary-height{
	  min-height: 60vh;
	}
  }
  @media screen and (min-height:945px) and (max-height:967px){
	.no-summary-height{
	  min-height: 61vh;
	}
  }
@media screen and (min-height: 968px) and (max-height: 1000px) {
	.histoy-table-height {
		min-height: 49vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 62vh;
	}
	.menu-table-container-history {
		min-height: 61vh;
	}
	.menu-table-container-cancelled {
		min-height: 57vh;
	}
	.menu-table-order-history {
		max-height: 51vh;
		min-height: 51vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 48vh;
		min-height: 48vh;
	}
}
@media screen and (min-height: 1000px) and (max-height: 1027px) {
	.histoy-table-height {
		min-height: 50vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 63vh;
	}
	.menu-table {
		min-height: 50vh;
	}
	.menu-table-container {
		min-height: 58vh;
	}
	.menu-table-container-history {
		min-height: 62vh;
	}
	.menu-table-container-cancelled {
		min-height: 59vh;
	}
	.menu-table-order-history {
		max-height: 53vh;
		min-height: 52vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 50vh;
		min-height: 50vh;
	}
}
@media screen and (min-height: 1028px) and (max-height: 1067px) {
	.histoy-table-height {
		min-height: 53vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 64vh;
	}
	.menu-table {
		min-height: 52vh;
	}
	.menu-table-container-history {
		min-height: 63vh;
	}
	.menu-table-container-cancelled {
		min-height: 59vh;
	}
	.menu-table-order-history {
		max-height: 54vh;
		min-height: 53vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 51vh;
		min-height: 51vh;
	}
}
@media screen and (min-height: 1068px) and (max-height: 1080px) {
	.histoy-table-height {
		min-height: 54vh;
		max-height: 1px;
	}
	.no-summary-height{
		min-height: 65.5vh;
	}
	.menu-table {
		min-height: 52vh;
	}
	.menu-table-container-history {
		min-height: 65vh;
	}
	.menu-table-container-cancelled {
		min-height: 63vh;
	}
	.menu-table-order-history {
		max-height: 57.5vh;
		min-height: 57.5vh;
	}
	.menu-table-order-history-cancelled {
		max-height: 55vh;
		min-height: 55vh;
	}
}

.category_text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #666666;
}

.item-qty-price-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #666666;
}

.item-qty-price-number {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}

.order-details-item-qty-price {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #000000;
}
.live-order-menu-item-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.header-svg-border-radius {
  border-radius: 4px;
}
.pause-order-margin-top {
  margin-top: 23px;
}
.order-card-hr {
  color: #bedbbf;
  opacity: 1;
}
.print-receipt-font {
  font-family: Roboto Monon, sans-serif !important;
  font-style: normal;
  color: #000000;
}
.incoming-order-accordion-margin {
  margin-top: 7px;
}

@media screen and (min-width: 1900px) {
  .max-width-full-screen {
    min-width: 6vw;
  }
  .max-width-full-screen-price {
    min-width: 2vw;
    text-align: end;
  }
}
.active-main-header-link-live-order {
  border-bottom: 3px solid var(--YELLOW_COLOR);
  width: fit-content;
}
.live-order-menu-item-footer-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.margin-top-menu-table-history {
  margin-top: 10px;
}
.button-live-order-height {
  height: 44px;
}
.dismiss-btn-just-cancelled-margin-right {
  margin-right: 20px;
}
.dismiss-row-padding-top {
  padding-top: 4px;
}
.contact-supp-hr-help {
  color: #999999;
  opacity: 1;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.contact-supp-hr-setting-other {
  color: #999999;
  opacity: 1;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.contact-supp-hr-setting-others {
  color: #999999;
  opacity: 1;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

textarea:focus-within,
textarea:focus-visible,
textarea:focus,
textarea:active,
textarea:hover {
  border: 1px solid #99cc00;
  border-radius: 4px;
  outline: none;
}

.no-order-gray-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #666666;
}
.no-order-dark-text {
  position: relative;
  word-break: break-word;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}
@media screen and (max-height: 667px) {
  .no-order-dark-text {
    top: 12rem;
  }
}
@media screen and (min-height: 668px) and (max-height: 768px) {
  .no-order-dark-text {
    top: 14rem;
  }
}
@media screen and (min-height: 769px) and (max-height: 827px) {
  .no-order-dark-text {
    top: 16rem;
  }
}
@media screen and (min-height: 828px) and (max-height: 900px) {
  .no-order-dark-text {
    top: 17.5rem;
  }
}
@media screen and (min-height: 901px) and (max-height: 1000px) {
  .no-order-dark-text {
    top: 19rem;
  }
}
@media screen and (min-height: 1001px) and (max-height: 1080px) {
  .no-order-dark-text {
    top: 22.5rem;
  }
}
@media screen and (min-height: 1081px) {
  .no-order-dark-text {
    top: 24rem;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1460px) {
  .custom-row {
    width: 50%;
  }
}
@media screen and (min-width: 1460px) and (max-width: 1600px) {
	.custom-row {
		width: 50%;
	}
}
@media screen and (min-width: 1820px) and (max-width: 2000px) {
  .custom-row {
    width: 35%;
  }
}

@media screen and (min-width: 2000px) and (max-width:2289px){
	.custom-row {
	  width: 30%;
	}
}
@media screen and (min-width:2289px){
	.custom-row{
	  width:25%;
	}
}
  
.word-break {
  word-break: break-word;
}
.order-list-row-padding {
  padding-top: 21.5px;
  padding-bottom: 21.5px;
}
.cancelled-color-history {
  color: #666666 !important;
}
/* @media (min-width: 1400px) {
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 29.666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 70%;
  }
} */
@media (min-width: 1900px) {
  .cutom-col-md-3 {
    flex: 0 0 auto;
    width: 11.5%;
  }
}
@media (min-width: 2000px) {
  .cutom-col-md-3 {
    flex: 0 0 auto;
    width: 12%;
  }
}
@media (min-width: 2100px) {
  .cutom-col-md-3 {
    flex: 0 0 auto;
    width: 12.5%;
  }
}
.fixed-width-count {
  min-width: 20px;
  max-width: 20px;
  text-align: end;
  margin-right: 12px;
}
.fixed-width {
  min-width: 46px;
  max-width: 46px;
  text-align: end;
}
.fixed-width-text {
  min-width: 184px;
  max-width: 184px;
}
.fixed-width-modifier-count {
  min-width: 20px;
  max-width: 20px;
  text-align: start;
  margin-left: 32px;
  margin-right: 10px;
}
.fixed-width-modifier-text {
  min-width: 154px;
  max-width: 154px;
}
.spacing-bottom-print {
  margin-bottom: 5px;
}

.fixed-width-text-no-price {
  min-width: 230px;
  max-width: 230px;
}
.fixed-width-modifier-text-no-price {
  min-width: 200px;
  max-width: 200px;
}
.padding-top-bottom-print-receipt {
  padding-top: 10px;
  padding-bottom: 10px;
}
.item-specific-instruction {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}
.item-specific-instruction-image {
  margin-top: -1.8px;
}
.order-specific-instructions-row-style {
  margin-top: 10px;
  /* margin-bottom: 10px; */
  padding-bottom: 10px;
  height: 34px;
  display: flex;
  align-items: center;
}
.order-details-special-instruction-note {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #660099;
  margin-right: 2px;
}
.order-details-special-instruction-note-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  top: 1px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}
.order-details-up-arrow-alignment {
  position: relative;
  top: -3px;
  font-size: 13px;
}
.print-notes-text {
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
}
.note-print-margin {
  margin: 0px 12px;
  width: 34px;
}
.print-text-special-instruction {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 15px;
  color: #000000;
  width: 214px;
}
.menu-header-container {
  display: grid;
  grid-template-columns: 3fr 6fr 3fr;
  justify-items: start;
  background: #f7f7f7;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  position:sticky;
  z-index: 3;
}
.menu-header-left {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 18px 20px;
}
.menu-header-left > .top-section-txt {
  font-family: Roboto Regular, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: #000000;
}
.menu-header-left > .button-section-txt {
  font-family: Roboto Regular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
}
.menu-header-container > .search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0px 20px 0px;
  background: #ffffff;
  border: 1px solid #bedbbf;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 64px;
}
.menu-body-container {
  display: grid;
  grid-template-columns: 2fr 10fr;
  justify-items: start;
  overflow-y: auto;
}
.menu-body-left {
  width: 100%;
  max-height: 70vh;
  min-height: 70vh;
  background: #f7f7f7;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 2;
}
.menu-body-left::-webkit-scrollbar-thumb {
  background: #bedbbf;
  border-radius: 10px;
}
.menu-body-left::-webkit-scrollbar {
  border-radius: 5px;
  width: 4px;
}
.menu-body-left-items-card {
  min-height: 140px;
}
.menu-card-marked-unavailable {
  padding: 37.04px 0px 37.09px 30px;
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
  align-items: center;
  gap: 20px;
  border: none;
  outline: none;
}
.menu-count-height {
  height: 39px
}
.menu-body-left-items-card-recent {
  margin: 30px 0px 30px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.menu-body-left-items-card-recent > .body-txt {
  display: flex;
  flex-direction: column;
}
.menu-body-left-items-card-others {
  margin: 30px 65px 30px 30px;
  display: flex;
  flex-direction: column;
}
.menu-normal-txt {
  font-family: Roboto Regular, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.15px;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.black-txt {
  color: #000000 !important;
}
.light-grey-txt {
  color: #666666 !important;
}
.green-txt {
  color: #004f00 !important;
}
.menu-body-right {
  width: 100%;
  max-height: 70vh;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  z-index: 1;
}
.menu-body-right::-webkit-scrollbar-thumb {
  background: #bedbbf;
  border-radius: 10px;
}
.menu-body-right::-webkit-scrollbar {
  border-radius: 5px;
  width: 4px;
}
.menu-main-container {
  margin: 30px 40px 0px 40px;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0px 0px;
}
.search-bar > .search-img {
  margin-right: 11px;
}
.menu-header-container > .right-container {
  width: 100%;
}
.input-menu {
  height: 44px;
  border: none;
  outline: none;
  box-shadow: none;
  margin-left: 10px;
  padding: 0px;
}

/* Menu Unavailable Screen */
.menu-card-marked-unavailable:active,
.menu-card-marked-unavailable:focus {
  background-color: #ebebeb;
}
.menu-all-item-available-text {
  margin-top: 20px;
  margin-bottom: 10px;
}
.menu-all-available-height {
  height: 100%;
}
.dairy-container {
  display: grid;
  grid-template-columns: 4fr 2fr;
  margin: 20px 0px;
}
.dairy-container > .right-part {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  justify-items: end;
  align-items: center;
  margin-right: 40px;
}
.modifier-main-container > .right-part {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  justify-items: end;
  align-items: center;
  margin-right: 40px;
}
.flex-gap-20 {
  gap: 20px;
}
.flex-gap-30 {
  gap: 30px;
}

.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mt-14 {
  margin-top: 14px;
}
.default-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.letter-spacing-15 {
  letter-spacing: 1.5px;
}
.dairy-modifier-container {
  display: grid;
  grid-template-columns: 1fr 6fr 2fr;
  justify-items: start;
  align-items: center;
}
.dairy-modifier-green-header {
  width: 98%;
  height: 48px;
  background: #bedbbf;
  border-radius: 8px 8px 0px 0px;
  margin-left: 30px;
}
.dairy-modifier-green-header > .modifier-header {
  padding: 12px 0px 12px 20px;
}
.dairy-modifier-item-card {
  display: grid;
  grid-template-columns: 4fr 2fr;
  justify-items: start;
  align-items: center;
  margin-left: 30px;
  margin-top: 14px;
}
.dairy-modifier-item-card-left {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dairy-modifier-item-card-right {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-items: start;
  align-items: center;
  gap: 20px;
  margin-left: 17px;
}

/* Simple Items Available / Unavailable */
.ml-64 {
  margin-left: 64px;
}
/* Custom toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff6600;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.disable-grey{
  background-color: gray !important;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #004f00;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 12px;
}
.letter-spacing-015 {
  letter-spacing: 0.15px;
}
.letter-spacing-O50 {
  letter-spacing: 0.5px;
}
.menu-body-left-items-card-active {
  background-color: #BEDBBF;
  color: #004f00;
}
.menu-body-left > .active-border {
  border-top: 1px solid #ebebeb;
}
.menu-body-left-items-card-marked-as-unavailable {
  margin: 30px 0px 30px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1029;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.overlay-box {
  position: relative;
  width: 40%;
  height: 35rem;
  left: 33.33%;
  top: 20%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
}

.menu-popup-header {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  width: 214px;
}

.help-ticket-category-text {
  font-family: "Roboto regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
}

.help-ticket-help-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
}
.help-dropdown-header-text {
  font-family: "Roboto regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #004f00;
}
.help-dropdown-content-text {
  font-family: "Roboto regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #666666;
}
.help-dropdown-menu {
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
  padding-left: 1.25rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 344px;
  width: 361px;
  overflow-y: scroll;
}
.help-dropdown-menu::-webkit-scrollbar {
  width: 0.313rem;
}
.help-dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
}
.help-menu-dropdown-text {
  font-family: "Roboto regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: #007f00;
}
.help-about-text {
  margin-left: 10px;
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #007f00;
}
.order-card-hr-first {
  color: #bedbbf;
  opacity: 1;
  margin-top: 0px;
}
.help-order-setting-bold-text-green {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #004f00;
}

.settings-order-setting-store-details-small-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
}
.settings-order-setting-store-details-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.settings-about-brands-header {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.help-aggregator-box-border {
  border: 2px solid #ebebeb;
  border-radius: 2px;
}
.settings-brand-data-table {
  max-height: 293px;
  min-height: 293px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 2px solid #ebebeb;
}
.settings-brand-data-table::-webkit-scrollbar {
  width: 0.313rem;
}
.settings-brand-data-table::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
}
.row-settings-brand-header {
  padding: 20px;
}
.settings-brand-details-row {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.help-ticket-description-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #004f00;
  margin-top: 20px;
  margin-bottom: 5px;
}
.menu-popup-green-header {
  width: 100%;
  height: 48px;
  background: #bedbbf;
  border-radius: 8px 8px 0px 0px;
  margin-left: 30px;
}
.menu-popup-header-txt {
  padding: 12px 0px 12px 20px;
}
.menu-popup-box {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 17px;
  min-height: 30rem;
  max-height: 30rem;
  overflow-y: scroll;
}
.menu-popup-box::-webkit-scrollbar {
  width: 0;
}
.menu-popup-box::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
}
.menu-poppup-row {
  height: 48px;
}
.custom-row-flex {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.menu-popup-spacing-modifier {
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 1.5px;
}
.text-upper {
  text-transform: uppercase;
}
.menu-popup-text-top {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  color: #666666;
}
.help-ticket-textarea {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  /* color: #999999; */
  background: #ffffff;
  border: 1px solid #bedbbf;
  box-sizing: border-box;
  border-radius: 4px;
}

.help-ticket-text:focus-within,
.help-ticket-text:focus-visible,
.help-ticket-text:focus,
.help-ticket-text:active,
.help-ticket-text:hover {
  border: 1px solid #99cc00;
  border-radius: 4px;
  outline: none;
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.help-ticket-submit-button {
  margin-top: 15px;
}
.help-ticket-submit-button-muted {
  border-radius: 4px;
  background-color: var(--MEDIUM_LIGHT_GRAY);
  color: var(--WHITE_COLOR);
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}
.help-ticket-submit-button-success {
  background-color: var(--DARK_GREEN_COLOR);
  color: var(--WHITE_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
  border: none;
}
.help-ticket-submit-button-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
}
.help-ticket-loading-svg {
  margin-top: 47px;
}
.help-ticket-loading-svg-text {
  margin-top: 32px;
  margin-bottom: 85px;
}
.help-ticket-submitting-request {
  font-family: "Roboto Bold", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 5px;
}
.help-ticket-please-wait {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #000000;
}

.help-ticket-error-svg {
  margin-top: 34px;
  margin-bottom: 20px;
}
.help-ticket-error-svg-text {
}
.help-ticket-please-wait-error {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
  margin-top: 16px;
}
.help-ticket-close-btn {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background-color: #f7f7f7;
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}
.help-ticket-close-btn:active {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background-color: #f7f7f7;
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}
.help-ticket-btn-close-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #007f00;
}
.help-ticket-retry-btn {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background: #007f00;
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}
.help-ticket-retry-btn:active {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background: #007f00;
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}
.help-ticket-btn-retry-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
}

.help-ticket-logged-svg {
  margin-top: 29px;
  margin-bottom: 15px;
}
.help-ticket-your-reference {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #666666;
}
.help-ticket-ref-number {
  color: var(--OLIVE_GREEN_COLOR);
}
.help-ticket-ack-text {
  margin-top: 16px;
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

/* animation */
@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spin {
  animation-name: spinning;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: linear;
}

.help-category-dropdown {
  background-color: #f7f7f7;
}
.help-dropdown .btn {
  border: 1px solid var(--DARK_GREEN_COLOR);
  background-color: #f7f7f7;
  color: var(--DARK_GREEN_COLOR);
  border-radius: 4px;
  font-size: 18px;
  line-height: 16px;
  padding: 14px 18px;
}

/* Simple Items Available / Unavailable */
.ml-64 {
  margin-left: 64px;
}
/* Custom toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff6600;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #004f00;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 12px;
}
.menu-search-box-shadow {
  background: #ffffff;
  border: 1px solid #99cc00;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.overlay-box-modifier {
  position: relative;
  width: 40%;
  height: 38rem;
  left: 33.33%;
  top: 15%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px;
}
.menu-popup-box-modifier {
  margin-top: 17px;
  min-height: 32rem;
  max-height: 32rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.menu-popup-box-modifier::-webkit-scrollbar {
  width: 0;
}
.menu-popup-box-modifier::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
}
.mb-10 {
  margin-bottom: 10px;
}
.pe-30 {
  padding-right: 30px;
}

/* Simple Items Available / Unavailable */
.ml-64 {
  margin-left: 64px;
}
/* Custom toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff6600;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #004f00;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 12px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}
.ml-66 {
  margin-left: 66px;
}
.aggregator-list {
  width: 717px;
  height: 357px;
  margin-left: 60px;
  border: 2px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 4px;
}
.brandImg {
  width: 68px;
  height: 68px;
}
.inactiveAgreegatorNames {
  width: 38px;
  height: 38px;
  border: 2px solid #ff6600;
  border-radius: 4px;
  margin-top: 15px;
}
.activeAgreegatorNames {
  width: 38px;
  height: 38px;
  border: 2px solid #ebebeb;
  border-radius: 4px;
  margin-top: 15px;
}
.agreegatoNotAvailable {
  width: 20px;
  height: 20px;
  margin-left: 21px;
  margin-top: 38px;
  margin-top: -15px;
}
.ml-5 {
  margin-left: 5px;
}
.view-bundle-modifiers {
  width: 92.2%;
  padding-bottom: 18px;
  margin-left: 50px;
  border-bottom: 1px solid #bedbbf;
  color: #007f00;
  gap: 10px;
}
.additional-info-section-wrapper {
  width: 98%;
  background: #f7f7f7;
  border: 1px solid #ff6600;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 20px 12px 20px;
  margin-left: 30px;
}
.additional-info-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}
.flex-gap-10 {
  gap: 10px;
}
.letter-spacing-O25 {
  letter-spacing: 0.25px;
}
.line-height-20 {
  line-height: 20px;
}
.additional-info-txt {
  padding-left: 5.94px;
}

.exception-page-header {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: -0.5px;
  color: #000000;
  margin-left: auto;
}
.mb-15 {
  margin-bottom: 15px;
}
.btn-white-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
}
.error-block {
  background: #ebebeb;
  border-radius: 8px;
  padding: 20px;
  width: 45%;
}
.heading-h4 {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 36px;
  color: #000000;
}
.error-page-normal-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}
.error-page-color-text {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #660099;
}
.error-store-details {
  font-family: "Roboto Regular", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #000000;
}
.mr-34 {
  margin-right: 34px;
}

/* Settings Page Updated Aggregator View */
/* #region */
.aggr-list-height{
  height: 288px;
  overflow-y: auto;
}
*::-webkit-scrollbar {
  width: 0.313rem;
}
*::-webkit-scrollbar-thumb {
  background-color: #bedbbf;
}
.p-20{
  padding: 20px;
}
  /* padding except bottom */
.peb-20{ 
  padding: 20px 20px 0px 20px;
}
.peb-10{ 
  padding: 10px 10px 10px 10px;
}
.aggr-problem-block{
  margin: 10px 10px 0px 10px;
  background: #F7F7F7;
  border: 1px solid #FF6600;
  border-radius: 4px;
  width: 97%;
}
.aggr-normal-block{
  margin: 10px 0px 0px 10px;  
}

.openHelp-and-retry-section {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
}
.open-help-section-text{
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #007F00;
}
.open-retry-section-text{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #007F00;
}
.ml-10{margin-left: 10px;}
.mb-18{margin-bottom: 18px;}
/* #endregion */

.print-text-special-instruction{
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 15px;
	color: #000000;	
	width:214px;
}

.overlay-top-popup {
	width: 100%;
	height: 44px;
	position: fixed;
	z-index: 1022;
	top: 100px;
	left: 0;
	background-color: rgba(0, 0, 0, 0);
}
.overlay-box-top-popup {
	position: relative;
	width: 100%;
	height: 44px;
	left: 0;
	top: 0px;
  }

.ml-15{
	margin-left: 15px;
}
.print-button-border{
	border-radius: 4px;
}

/* Order History Summary */
.summary-content > p {
	margin: 0 !important;
}
.summary-content-values  {
	letter-spacing: -0.5px;
	color: var(--OLIVE_GREEN_COLOR);
	font-size: 60px;
	line-height: 72px;
	word-break: break-all;
	font-family: 'Roboto Light', sans-serif;
}
.orders-details-flex {
	font-size: 1.3vw;
	/* line-height: 24px; */
	letter-spacing: 0.5px;
	color: #000000;
}
.summary-content-grid {
	padding-top: 20px;
}

.menu_count_header {
  display: inline;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 12px;
  border-radius: 54px;
  font-size: 12px;
  color: black;
  line-height: 16px;
}


/* CSS for sticky header on scroll */
/* #region Sticky Header On-scroll */
.sticky-header{
  position: sticky;
  top:20px;
  height:58px;
  z-index: 10;
  background-color:#f7f7f7;
  color: #000000;
  display: flex;
  align-items: center;
}
.sticky-header-text{
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.18px;
  color: #004F00;
}
.sticky-header-right-rectangle{
  height: 58px;
  width: 5px;
  background: #BEDBBF;
  border-radius: 0px 10px 10px 0px;
}
.fix-block-for-sticky{
  position: sticky;
  top:0;
  z-index: 10;
  height: 20px;
  background-color: white;
}
/* #endregion */
.left-card-item{
  width: 100%;
  min-height: 140px;
}
.left-card-item-active{
  background-color: #BEDBBF;
}
.left-card-items-body{
  display: grid;
  grid-template-columns: 1fr 23fr;
  font-size: 18px;
}
.modifier-item-container{
  display: flex;
  justify-content: space-between;
}
.modifier-container {
  display: grid;
  grid-template-columns: 4fr 2fr;
  min-height: 68px;
  align-items: center;
}
.modifier-container > .right-part {
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  justify-items: end;
  align-items: center;
  margin-right: 40px;
}
.modifier-name-text{
  margin-left: 204px;
}
.modifier-border{
  border: 1px solid #999999;
}
.margin-left-184{
  margin-left: 184px;
}
.mark-as-unavailable-tab{
  display: grid;
  grid-template-columns: 1fr 23fr;
  font-size: 18px;
}
.flex-gap-15{
  gap: 15px;
}
.mark-as-unavailable-tab-count {
  display: inline;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 54px;
  font-size: 12px;
  line-height: 16px;
  margin: 10px 10px 0px 0px;
}
.unavailable-count-active{
  color: #007F00;
  background-color: #FFFFFF;
  border: 1px solid #007F00;
  box-sizing: border-box;
  border-radius: 54px;
}
.unavailable-count-inactive{
  background-color: #FF6600;
  color: black;
}
.tab-custom-border{
  min-height: 140px;
  min-width: 10px;
  max-width: 10px;
  background:  #007F00;;
  border-radius: 0px 10px 10px 0px;
}
.all-product-logo{
  margin-top: 34px;
  /* margin-left: 51px; */
}
.mark-as-unavailable-logo{
  /* margin-left: 68px; */
  margin-bottom: 15px;
}
.border-setting-brand{
  border: 2px solid #EBEBEB;
  border-radius:4px;
}
.left-card-bottom-border{
  border-bottom: 1px solid #BEDBBF;
}
.search-header{
  color: #004F00;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.18px;
  margin: 35px 0px 35px 40px;
}

@media all and (max-width: 750px){
  .col-width {
      margin-top: 20px;
      width: 100% !important;
  }
  .col-other-first {
      display: none;
  }
  .col-other-last {
    margin-top: 20px;
    width: 100% !important;
  }
  .remove-margin-top{
    margin-top: 0 !important;
  }
}

@media all and (max-width: 1250px) and (min-width: 751px){
    .col-width {
        width: 50% !important;
    }
    .col-other-first {
        display: none;
    }
    .col-other-last {
      width: 100%;
      margin-top: 20px;
    }

}

@media all and (min-width: 1251px) {
  .col-other-last {
    display: none;
  }
  
}

@media screen and (max-width: 1457px){
  .mark-as-unavailable-logo{
    align-items: center;
    margin-left: 0px;
  }
  .all-product-logo{
    align-items: center;
    margin-left: 0px;
  }
  .letter-spacing-015{
    text-align: center;
    margin-bottom: 34px;
  }
  .menu-left-tab-small{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
}

.left-card-text {
  letter-spacing: 0.15px;
  font-weight: bold;
  margin-bottom: 34px;
}
@media screen and (max-width: 1241px){
  .make-flex-col-on-overflow-for-mark-as-unavailable-tab{
    flex-direction: column !important;
  }
}
@media screen and (max-width: 972px){
  .make-flex-col-on-overflow-for-all-product-tab{
    flex-direction: column !important;
  }
}
@media screen and (min-height: 1100px) {
  .menu-main-container{
    min-height: 88vh;
  }
  .menu-body-left{
    min-height: 81vh;
    max-height: 81vh;
  }
  .menu-body-right{
    min-height: 81vh;
    max-height: 81vh;
  }
}

@media all and (max-width: 1400px){
  .active-order-table-for-smaller-screen{
    display: grid;
    grid-template-columns: 3fr 2.25fr 2.25fr 2.25fr 2.25fr;
  }
  .width-100-active-order-table{
    width: 100%;
  }
  .order-list-item-margin{
    margin-right: 0px;
  }
  .hide-eta-icon-smaller-screen{
    display: none;
  }
}
.mt-10{
  margin-top: 10px;
}
.pt-12{
  padding-top: 12px;
}
.pb-12{
  padding-bottom: 12px;
}
.pt-15{
  padding-top: 15px;
}
.pb-15{
  padding-bottom: 15px;
}
.pl-18{
  padding-left: 18px;
}
.mr-18{
  margin-right: 18px;
}
.ml-45{
  margin-left: 45px;
}

.letter-spacing-125{
  letter-spacing: 1.25px;
}
.mark-as-completed-btn {
  max-width: 255px;
  max-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px 18px;
  background: #007F00;
  border: 1px solid #007F00;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}
.sea-mist-bg{
  background-color: var(--SEA_MIST_COLOR);
}
.active-order-left-bar{
  min-width: 4px;
  max-width: 4px;
  min-height: 100%;
  background: #007F00;
  border-radius: 0px 4px 4px 0px;
}
.active-order-bottom-border{
  margin-bottom: 2px;
}

.complete-order-body-text{
  font-family: 'Roboto Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
}

.complete-order-footer-text{
  font-family: 'Roboto Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
}

.complete-order-footer{
  background-color: #F7F7F7;
  height:141px;
  padding: 14px 20px 20px 20px;
}

.complete-order-container{
  display: grid;
  grid-template-columns: 1fr 9fr 2fr;
  padding: 10px 20px 10px 20px;
  margin: 0px 20px;
  align-items: center;
}

.complete-order-close-img {
  height:24px;
  width:24px;
  cursor: pointer;
}

.complete-order-img{
  height: 34px;
  width: 34px;
  margin-right:10px;
}

.cancel-order-image{
  height: 64px;
  width: 64px;
}

.modal-body-container{
  display: grid;
  grid-template-columns: 1fr 11fr;
  padding: 0px 20px;
  align-items: center; 
}
.modifier-main-container{
  display: grid;
  grid-template-columns: 4fr 2fr;
  align-items: center;
  width: 100%;
  min-height: 48px;
  margin-bottom: 20px;
}
.modifier-main-container > .modifier-title{
  padding: 12px 0px 12px 64px
}
.modifier-main-container > .modifier-availability{
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  justify-items: end;
  align-items: center;
  margin-right: 40px;
}
.padding-left-52 {
  padding-left: 50px;
}

.grey-bottom-border{
  padding-bottom: 10px;
  border-bottom: 1px solid var(--MEDIUM_LIGHT_GRAY);
}
.bundle-heading{
  font-family: 'Roboto Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #666666;
  text-align: center;
  margin-top: 13px;
}

.bundle-name{
  font-family: 'Roboto Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #000000;
}
.font-size-11{
  font-size: 11px;
}
.font-weight700{
  font-weight: 700;
}
.dotted-bottom-border{
  border-bottom: 2px dashed rgba(0, 0, 0, 0.25);
}
.padding-left-40{
  padding-left: 40px;
}
.price-col-for-smaller-screen{
  display: none !important;
}
@media all and (max-width: 1283px) and (min-width: 992px){
  .price-col-for-larger-screen{
    display: none !important;
  }
  .price-col-for-smaller-screen{
    display: flex !important;
  }
}
.code-info-padding-for-smaller-screen{
  padding-top: 10px;
}
@media all and  (min-width: 1550px){
  .cust_info-for-larger-screen{
    width: 66.66% !important;
  }
  .code-info-for-larger-screen{
    width: 33% !important;
  }
  .code-info-padding-for-smaller-screen{
    padding-top: 0px;
  }
  .code-info-flex-dir-for-larger-screen{
    justify-content: end !important;
    padding-right: 16px;
  }
}
@media all and (max-width: 992px) and (min-width: 450px){
  .cust_info-for-larger-screen{
    width: 66.66% !important;
  }
  .code-info-for-larger-screen{
    width: 33% !important;
  }
  .code-info-padding-for-smaller-screen{
    padding-top: 0px;
  }
  .code-info-flex-dir-for-larger-screen{
    justify-content: end !important;
    padding-right: 16px;
  }
}
.pt-14{
  padding-top: 14px;
}
.margin-top-live-order-with-bar-notification {
  margin-top: 43px;
}
.order-details-table{
  
  border-left: 2px solid #EBEBEB;
  border-right: 2px solid #EBEBEB;
  border-bottom: 2px solid #EBEBEB;
  border-radius: 4px;
  /* margin-bottom: 20px; */

}
.order-details-main-container{
  min-height: 80vh;
  max-height: 80vh;
}
.venetian-red-color{
  color: #660099;
}
.cultured-grey-bgc{
  background-color: #f7f7f7;
}
.order-detail-table-first-col-border{
  margin: 0px 0px 0px 12px;
  border-top: 1px solid #999999;
  width: 98.5%;
}
.order-details-scrollable-view{
  max-height: 50vh;
}
.order-detail-table-summary-border{
  margin: 0px 0px 0px 12px;
  border-bottom: 1px solid #999999;
  width: 98.5%;
}
.edit-order-view-quantity{
  border: 1px solid #BEDBBF;
  border-radius: 4px;
  padding: 10px 0px 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.update-order-active-btn-in-edit-order-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  gap: 10px;

  width: 165px;
  height: 44px;

  background-color: #007f00;
  font-size: 18px;
  line-height: 16px;

  letter-spacing: 1.25px;
  text-transform: capitalize;

  color: #ffffff;
  border-radius: 4px;
}
.dark-green-bgc{
  background-color: var(--DARK_GREEN_COLOR);
}
.medium-light-grey-bgc{
  background-color: var(--MEDIUM_LIGHT_GRAY);
}
@media screen and (min-height: 1100px)  {
  .order-details-scrollable-view{
      max-height: 61vh;
  }
}
@media screen and (min-height: 1000px) and (max-height: 1100) {
  .order-details-scrollable-view{
      max-height: 55vh;
  }
}
@media screen and (min-height: 900px) and (max-height: 1000) {
  .order-details-scrollable-view{
      max-height: 50vh;
  }
}
@media screen and (min-height: 830px) and (max-height: 900px) {
  .order-details-scrollable-view{
      max-height: 45vh;
  }
}
@media screen and (min-height: 750px) and (max-height: 830px) {
  .order-details-scrollable-view{
      max-height: 40vh;
  }
}
@media screen and (min-height: 680px) and (max-height: 750px) {
  .order-details-scrollable-view{
      max-height: 35vh;
  }
}
@media screen and (min-height: 600px) and (max-height: 680px) {
  .order-details-scrollable-view{
      max-height: 28vh;
  }
}

.rotating-loader{
  width: 20px;
  height: 20px;
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* @media screen and (min-height: 500px) and (max-height: 780px) {
  .order-details-scrollable-view{
      max-height: 30vh;
  }
}
@media screen and (min-height: 650px) and (max-height: 400px) {
  .order-details-scrollable-view{
      max-height: 20vh;
  }
} */
.loader-available{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;
}
.green-underline-link {
  color: #004f00;
}
.green-underline-link:hover {
  color: #004f00;
}
.offline-popup{
/* There is noting in class because it is only use to override default width of Bootstrap Modal  */
   z-index: 10000 !important;
}
.offline-popup > .modal-content{
  min-width: 542px;
  min-height: 254px;
}
.offline-popup-retry-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  gap: 10px;
  width: 83px;
  height: 44px;
  background: #007F00;
  border-radius: 4px;
}
.context-menu-cursor{
  cursor: context-menu !important;
}

.order-count-down {
  color: var(--ORANGE_COLOR);
  font-weight: bold;
}
.ml-12{
  margin-left: 12px;
}
.total-item-count-in-incoming-order-card {
  background-color: var(--WHITE_COLOR);
  border: 1px solid var(--DARK_GREEN_COLOR);
  width: fit-content;
  padding: 6px 10px;
  border-radius: 54px;
  color: var(--OLIVE_GREEN_COLOR);
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
.customer-info-in-incoming-order-card{
  /* min-height: 46px; */
  background-color: #F7F7F7;
  padding: 7px 10px;
  /* padding-top: 7px;
  padding-bottom: 7px; */

}
.footer-in-incoming-order-card{
  min-height: 83px;
  background-color: #EBEBEB;
}
.oder-items-view-in-incoming-order{
  max-height: 301px;
  overflow-y: auto;
}
.grey-border{
  min-height: 1px;
  max-height: 1px;
  background-color: #999999 !important;
  color: #999999 !important
}
.line-height-24 {
  line-height: 24px;
}
.edit-order-view-quantity-for-incoming-order-card{
  width: 66px;
  height: 44px;
  min-height: 44px;
  max-height: 44px;
  min-width: 66px;
  max-width: 66px;
  border: 1px solid #BEDBBF;
  border-radius: 4px;
  padding: 10px 0px 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partially-accept-btn-incoming-order {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 18px;
  gap: 10px;
  position: absolute;
  width: 120px;
  height: 44px;
  background-color: #007F00;
  border-radius: 4px;
}

.store-offline-time {
  float: left;
  position: fixed;
  margin-left: -45px;
  font-size: 14px;
  margin-top: 40px;
}
